import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import { CTAPage } from "interfaces/form-preview.interface";
import CTAFormPage from "./CTAFormPage";
import { FormControlItemLayout } from "components/forms/form-components/FormControlItem";
import { CUSTOMER_DOCUMENT_TYPES } from "enums/customer-trade-application-form.enums";
import { FormStep, getSteps } from "utils/form.utils";
import API from "api";

const PaymentInformationFormPreview = () => {
  const params = useParams();
  const [pages, setPages] = useState<CTAPage[]>([]);
  const [formNumber, setFormNumber] = useState<number | undefined>(undefined);
  const [revisionNumber, setRevisionNumber] = useState<number | undefined>(undefined);

  const reorderCallback = useCallback(
    (overflowedPage: number) => {
      reorder(overflowedPage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [pages]
  );

  const reorder = (overflowedPage: number) => {
    if (overflowedPage === pages.length) {
      // overflowed page is the last page
      const tempPages = [...pages, { pageNumber: pages.length + 1, items: [] }];
      let lastItem: any;
      const newPages = tempPages.map((page) => {
        if (page.pageNumber === overflowedPage) {
          lastItem = page.items[page.items.length - 1];
          const newItems = page.items.slice(0, -1);
          return { ...page, items: [...newItems] };
        } else if (page.pageNumber === overflowedPage + 1) {
          return { ...page, items: [lastItem] };
        } else {
          return { ...page };
        }
      });
      setPages([...newPages]);
    } else {
      // already created a new page but still overflowed
      let lastItem: any;
      const newPages = pages.map((page) => {
        if (page.pageNumber === overflowedPage) {
          lastItem = page.items[page.items.length - 1];
          const newItems = page.items.slice(0, -1);
          return { ...page, items: [...newItems] };
        } else if (page.pageNumber === overflowedPage + 1) {
          return { ...page, items: [lastItem, ...page.items] };
        } else {
          return { ...page };
        }
      });
      setPages([...newPages]);
    }
  };

  useEffect(() => {
    const getFormData = async () => {
      try {
        const { data } = await API.get(`/customer-trade-application-form/pdf-preview/${params.formId}`);
        setFormNumber(data.DWDOCID);
        setRevisionNumber(data.revisionNumber);
        const dataArray: any[] = [];
        getSteps(CUSTOMER_DOCUMENT_TYPES.CUSTOMER_PAYMENT_INFORMATION_FORM, false, data).forEach(
          (step: FormStep, index: any) => {
            if (step.label !== "Review Before Submission") {
              dataArray.push({ type: "header", value: step.label });
            }
            if (step.label === "Guarantor Details") {
              dataArray.push({
                type: "link",
                value: "https://www.jfcaustralia.com.au/terms",
              });
            }

            step.layout?.forEach((field: FormControlItemLayout, index: number) => {
              const value = data[field.name] ?? "";

              if (step.label === "Direct Debit") {
                if (field.name === "DIRECT_DEBIT_CHOSEN") {
                  if (value === "Yes") {
                    return dataArray.push(
                      {
                        type: field.type ?? "item",
                        label: field.label ?? "No label",
                        value,
                      },
                      {
                        type: "header",
                        value: "Request and Authority to debit the account named below to pay",
                      },
                      { type: "Direct Debit Paragraph 1" }
                    );
                  } else {
                    return dataArray.push({
                      type: field.type ?? "item",
                      label: field.label ?? "No label",
                      value,
                    });
                  }
                }
                if (field.name === "BANK_NAME") {
                  return dataArray.push(
                    { type: "header", value: "Nominated Account Details" },
                    {
                      type: field.type ?? "item",
                      label: field.label ?? "No label",
                      value,
                    }
                  );
                }

                if (field.name === "ACKNOWLEDGEMENT_FULL_NAME_DD_1") {
                  return dataArray.push(
                    { type: "header", value: "Acknowledgement & Signatures" },
                    { type: "Direct Debit Paragraph 2" },
                    {
                      type: field.type ?? "item",
                      label: field.label ?? "No label",
                      value,
                    }
                  );
                }
                if (field.name === "DIRECT_DEBIT_SERVICE_AGREEMENT") {
                  return dataArray.push(
                    {
                      type: "header",
                      value: "Direct Debit Request Service Agreement",
                    },
                    {
                      type: "link",
                      value: "https://www.jfcaustralia.com.au/direct-debit-request-service-agreement/",
                    },
                    {
                      type: field.type ?? "item",
                      label: field.label ?? "No label",
                      value,
                    }
                  );
                }

                if (!field.label) {
                  return;
                }

                return dataArray.push({
                  type: field.type ?? "item",
                  label: field.label ?? "No label",
                  value,
                });
              }

              if (field.name === "FULL_NAME_AOC_1") {
                return dataArray.push(
                  { type: "header", value: "Signed by Proprietors/Directors" },
                  {
                    type: field.type ?? "item",
                    label: field.label ?? "No label",
                    value,
                  }
                );
              }
              if (field.name === "SIGNATURE_AOC_2") {
                return dataArray.push(
                  {
                    type: field.type ?? "item",
                    label: field.label ?? "No label",
                    value,
                  },
                  {
                    type: "text",
                    value: "**All company Directors must sign the attached guarantee**",
                  }
                );
              }

              if (field.name === "GUARANTOR_FULL_NAME") {
                return dataArray.push(
                  { type: "header", value: "Guarantor 1: (Owner or Director)" },
                  {
                    type: field.type ?? "item",
                    label: field.label ?? "No label",
                    value,
                  }
                );
              }
              if (field.name === "GUARANTOR_FULL_NAME_2") {
                return dataArray.push(
                  { type: "header", value: "Guarantor 2: (Owner or Director)" },
                  {
                    type: field.type ?? "item",
                    label: field.label ?? "No label",
                    value,
                  }
                );
              }
              if (field.name === "DRIVERS_LICENSE") {
                return dataArray.push(
                  { type: "header", value: "Guarantor 2: (Owner or Director)" },
                  {
                    type: field.type ?? "item",
                    label: field.label ?? "No label",
                    sublabel: "If not Australia Citizen, please attach a copy of the passport.",
                    value,
                  }
                );
              }

              return dataArray.push({
                type: field.type ?? "item",
                label: field.label ?? "No label",
                value,
              });
            });

            dataArray.push({ type: "divider" });
          }
        );

        setPages([{ items: dataArray, pageNumber: 1 }]);
      } catch (error) {
        console.error(error);
      }
    };
    getFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {pages.length
        ? pages.map((page, index) => {
            return (
              <CTAFormPage
                key={index}
                reorder={reorderCallback}
                items={page.items}
                page={page.pageNumber}
                totalPages={pages.length}
                formNumber={formNumber}
                revisionNumber={revisionNumber}
                formType="Payment Information Form"
              />
            );
          })
        : null}
    </Box>
  );
};

export default PaymentInformationFormPreview;
